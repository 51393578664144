/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { App, ProviderProps as AppProps } from '@oneaudi/oneaudi-os-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Loader, Text } from '@audi/audi-ui-react';
import { useQuery } from '@apollo/client';
import { useI18n } from '@oneaudi/i18n-context';
import { queryString } from '../queryHook';
import { ConfigProps, PartnerBusinessCard } from './components/PartnerBusinessCard';
import { mapContent } from './components/headless/ContentInterfaces';
import FeatureServiceContext from './FeatureServiceContext';

import { Container } from './style';
import { pruneOpeningHours } from '../lib/use-partner-data';
import { messages } from '../i18n/messages';
import { toISO3CountryCodeDealerFormatter } from '../lib/2toIso3';

interface FeatureAppProps extends Omit<AppProps, 'config'> {
  config?: Config;
}

interface Config extends Omit<ConfigProps, 'partnerID'> {
  [x: string]: any;
  partnerID?: string;
  variantConfig?: any;
  showVariant?: any;
}

interface PBCProps {
  config?: Config;
}
// eslint-disable-next-line
const PBCWrapper: React.FC<PBCProps> = ({ config }: PBCProps) => {
  const [pbcConfig, setPbcConfig] = React.useState<ConfigProps>();

  React.useEffect(() => {
    if (config?.partnerID || config?.pbcPartnerID) {
      setPbcConfig({
        ...config,
        partnerID: config?.partnerID || config?.pbcPartnerID,
      });
    } else {
      setPbcConfig({
        ...config,
        partnerID: 'DEUA26603',
      });
      // eslint-disable-next-line
      console.error(
        'Neither a content id to fetch partnerID from content or a config with partnerID is specified! Component will not render!',
      );
    }
  }, [config]);

  React.useEffect(() => {
    if (config?.partnerID) {
      setPbcConfig({
        ...config,
        variantConfig: {
          ...config?.variantConfig,
        },
        partnerID: config?.partnerID,
      });
    } else {
      // eslint-disable-next-line
      console.error('"partnerID" is missing! Component will not render!');
    }
  }, [config]);

  const regExp = /[a-zA-Z]/g;
  const isPartnerIdComplete = regExp.test(pbcConfig?.partnerID as string);
  const partnerId = isPartnerIdComplete
    ? pbcConfig?.partnerID
    : toISO3CountryCodeDealerFormatter(config?.locale as string, pbcConfig?.partnerID as string);

  // eslint-disable-next-line
  const { data, loading } = useQuery(queryString, {
    variables: { partnerId },
    fetchPolicy: 'no-cache',
  });

  let dealerData: any;

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Loader />
      </div>
    );
  }

  if (data) {
    const { dealerById } = data;
    if (dealerById?.openingHours) {
      dealerById.openingHours = pruneOpeningHours(dealerById.openingHours);
      dealerData = dealerById;
    }
    dealerData = dealerById;

    return <PartnerBusinessCard config={pbcConfig} data={dealerData} />;
  }

  console.error('Partner data does not exist.');
  return (
    <div style={{ textAlign: 'left' }}>
      <Text variant="order3" spaceStackEnd="xxs">
        {useI18n(messages.missingPartnerDataErrorHeader)}
        test
      </Text>
      <Text variant="copy1">{useI18n(messages.missingPartnerDataErrorMessage)}</Text>
    </div>
  );
};

PBCWrapper.defaultProps = {
  config: undefined,
};
// eslint-disable-next-line
const FeatureApp: React.FC<FeatureAppProps> = ({
  id = 'oneaudi-feature-app-pbc',
  config,
  ...inherited
}: FeatureAppProps) => {
  const context = React.useContext(FeatureServiceContext);
  const { contentService } = context;

  const [rawContent, setRawContent] = React.useState<any>(contentService?.getContent());
  let content = null;

  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener(
        'pbcCtaClicked',
        (e: any) => {
          console.log(e?.detail);
        },
        false,
      );
    }
    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener(
          'pbcCtaClicked',
          (e) => {
            console.log(e);
          },
          false,
        );
      }
    };
  });

  React.useEffect(() => {
    const listener = () => {
      setRawContent(contentService?.getContent());
    };
    contentService?.onContentChange(listener);

    return () => {
      contentService?.removeOnContentChange(listener);
    };
  }, [contentService]);

  // eslint-disable-next-line
  content = mapContent(rawContent);

  const pbcConfig = {
    partnerID: config?.partnerID ? config?.partnerID : content?.partnerID,
    variantConfig: config?.variantConfig
      ? config?.variantConfig
      : content?.variantConfigHeadless || content?.variantConfig,
    showVariant: config?.showVariant
      ? config?.showVariant
      : content?.showVariant || content?.pbcShowVariant,
    locale: config?.locale && config?.locale,
    imprint: config?.imprint && config?.imprint,
    registrationNumber: config?.registrationNumber && config?.registrationNumber,
  };

  const appConfig: { [k: string]: string } = { appName: __FEATURE_APP_NAME__ };

  const standAlonePBC = !config?.partnerID || !content?.pbcPartnerID;

  return (
    <App config={appConfig} id={id} {...inherited}>
      <div data-test-id="PBC">
        <Container variant={pbcConfig?.showVariant} standAlonePBC={standAlonePBC}>
          <PBCWrapper config={pbcConfig} />
        </Container>
      </div>
    </App>
  );
};

export default FeatureApp;
