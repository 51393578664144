/* eslint-disable @typescript-eslint/no-unsafe-argument */
export const canUseDOM = typeof window !== 'undefined';

export function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');
}

// eslint-disable-next-line
type Func = (...args: any[]) => any;

export const throttle = (fn: Func, wait: number): Func => {
  let previouslyRun: number;
  let queuedToRun: number;

  // eslint-disable-next-line
  return function invokeFn(...args: any[]) {
    const now = Date.now();

    if (queuedToRun) {
      clearTimeout(queuedToRun);
    }

    if (!previouslyRun || now - previouslyRun >= wait) {
      fn(...args);
      previouslyRun = now;
    } else if (canUseDOM) {
      queuedToRun = window.setTimeout(invokeFn.bind(null, ...args), wait - (now - previouslyRun));
    }
  };
};
