/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { ThemeProvider, audiLightTheme } from '@audi/audi-ui-react';
import { Partner } from '@oneaudi/audi-partner-utils';
import { rehydrateValue, storeForHydration } from '@oneaudi/oneaudi-os-utils';
import { useInViewEffect } from '@oneaudi/feature-app-utils';
import FeatureServiceContext from '../FeatureServiceContext';
import { useTrackingManager } from '../useTrackingManager';
import { PartnerBusinessCardFullLong } from './PartnerBusinessCardFullLong';
import { PartnerBusinessCardAddress } from './PartnerBusinessCardAddress';
import { PartnerBusinessCardTag } from './PartnerBusinessCardTag';
import { PartnerBusinessCardFixedNarrow } from './PartnerBusinessCardFixedNarrow';
import { SocialMediaConfig } from './socialMedia/socialMediaBar';
import { createPartner } from './PartnerData';
import { CtaBarButtonConfig } from './CtaBar';

import { PartnerProvider } from '../PartnerProvider';
import { layoutStyleStrings } from '../../constants';

const { selectedInfoNarrow, selectedInfoLong, dealerAddressAndContactInfo, dealerAndAddress } =
  layoutStyleStrings;

type PartnerBusinessCardState = {
  content?: ConfigProps;
};
export type VariantConfig = {
  contactDetails: {
    displayOpeningHours?: boolean;
    displayCtaBar?: boolean;
    displayOfficialName?: boolean;
    displayEmail?: boolean;
    displayPhone?: boolean;
    isLinkedToPWS?: boolean;
    showMoreInfoLink?: boolean;
    hasOuterSpacing?: boolean;
    isGoogleMapLink?: boolean;
    showImage?: boolean;
    showServiceList?: boolean;
    showJsonLd?: boolean;
    showSocialMediaLinks: boolean;
  };

  displayOpeningHours?: boolean;
  displayCtaBar?: boolean;
  displayOfficialName?: boolean;
  displayEmail?: boolean;
  displayPhone?: boolean;
  isLinkedToPWS?: boolean;
  showMoreInfoLink?: boolean;
  hasOuterSpacing?: boolean;
  isGoogleMapLink?: boolean;
  showImage?: boolean;
  showServiceList?: boolean;
  showJsonLd?: boolean;
  showSocialMediaLinks: boolean;

  ctaBarButtonConfig?: CtaBarButtonConfig;
  socialMedia?: SocialMediaConfig;
};

export interface ConfigProps {
  partnerID: string;
  showVariant?: keyof typeof layoutStyleStrings;
  variantConfig?: VariantConfig | any;
  locale?: string;
  imprint?: string;
  registrationNumber?: string;
  countryCode?: string;
}

const hydrationStorePartnerData = `${__FEATURE_APP_NAME__}-partner-data`;
const rehydratedPartnerData = rehydrateValue<Partner | undefined>(hydrationStorePartnerData);

export interface PartnerBusinessCardProps {
  config?: ConfigProps;
  data?: any;
}

export const PartnerBusinessCard = (props: PartnerBusinessCardProps): React.ReactElement => {
  const context = React.useContext(FeatureServiceContext);
  const [state, setState] = React.useState<PartnerBusinessCardState>({
    content: context.contentService?.getContent(),
  });
  const trackingManager = useTrackingManager();
  const { config, data } = props;

  context.contentService?.onContentChange(async () => {
    setState({ content: context.contentService?.getContent() });
  });

  const pbcvariant: string = config?.showVariant || state.content?.showVariant || selectedInfoLong;

  let partnerID: string;
  if (state.content?.partnerID) {
    partnerID = state.content?.partnerID;
  } else if (config?.partnerID) {
    partnerID = config?.partnerID;
  } else {
    // eslint-disable-next-line no-console
    console.debug('"partnerID" is missing! Partner data will not be fetched');
    // TODO Refactor component to render variants depending on partnerID is defined or not
    partnerID = '';
  }
  const [fetchedPartnerData, setFetchedPartnerData] = React.useState<Partner | undefined>(
    rehydratedPartnerData,
  );

  const partner = createPartner(fetchedPartnerData);

  const variantConfig: VariantConfig = config?.variantConfig
    ? config.variantConfig
    : state.content?.variantConfig;

  React.useEffect(() => {
    if (data) {
      setFetchedPartnerData(data);
      storeForHydration(hydrationStorePartnerData, data);
    }
  }, [data]);

  let vtpData: any;
  if (config && config.imprint) {
    const { imprint, registrationNumber } = config;
    vtpData = { imprint, registrationNumber };
  }

  React.useEffect(() => {
    if (typeof window !== 'undefined') trackingManager.pageLoad(pbcvariant, partnerID);
  }, [pbcvariant, partnerID, trackingManager]);

  // TODO refactor impression tracking only should happen when partner data are loaded!
  useInViewEffect(() => {
    trackingManager.impression(
      pbcvariant,
      partner?.nameEnhanced || '',
      partner?.openingHours?.departments,
      variantConfig?.ctaBarButtonConfig,
    );
  });

  if (partner) {
    return (
      <ThemeProvider theme={audiLightTheme}>
        <PartnerProvider partner={partner}>
          {pbcvariant === selectedInfoLong && <PartnerBusinessCardFullLong {...variantConfig} />}
          {pbcvariant === dealerAddressAndContactInfo && (
            <PartnerBusinessCardAddress variantConfig={variantConfig} />
          )}
          {pbcvariant === dealerAndAddress && (
            <PartnerBusinessCardTag variantConfig={variantConfig} vtpData={vtpData} />
          )}
          {(pbcvariant === selectedInfoNarrow || pbcvariant === 'fixed-narrow') && (
            <PartnerBusinessCardFixedNarrow {...variantConfig} />
          )}
        </PartnerProvider>
      </ThemeProvider>
    );
  }
  return <div />;
};
