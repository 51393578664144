import * as React from 'react';
import { LayoutProps } from '@audi/audi-ui-react';
import { v4 as uuidGenerator } from 'uuid';
import { SocialButton, StyleSocialLinkLayout } from './style';
import {
  FACEBOOK_ICON,
  TWITTER_ICON,
  INSTAGRAM_ICON,
  LINKENDIN_ICON,
  YOUTUBE_ICON,
  VIMEO_ICON,
} from '../Icons';

export type SocialMediaConfig = {
  facebook: string;
  facebookUrl?: string;
  instagram: string;
  instagramUrl: string;
  twitter: string;
  twitterUrl: string;
  linkedln: string;
  linkedlnUrl: string;
  youtube: string;
  youtubeUrl: string;
  vimeo: string;
  vimeoUrl: string;
}[];

export type SocialBarProps = LayoutProps & {
  socialMedia: SocialMediaConfig;
};
// eslint-disable-next-line
export const SocialMediaBar = ({ socialMedia }: SocialBarProps): React.ReactElement => {
  return (
    <StyleSocialLinkLayout>
      {socialMedia.map((socialMediaLinkInfo) => {
        return (
          <React.Fragment key={uuidGenerator()}>
            {socialMediaLinkInfo.facebook ? (
              <SocialButton
                variant="text"
                href={socialMediaLinkInfo.facebookUrl}
                spaceStackStart="m"
                target="_blank"
              >
                {FACEBOOK_ICON}
              </SocialButton>
            ) : (
              ''
            )}
            {socialMediaLinkInfo.instagram ? (
              <SocialButton
                variant="text"
                href={socialMediaLinkInfo.instagramUrl}
                spaceStackStart="m"
                target="_blank"
                className="arrow"
              >
                {INSTAGRAM_ICON}
              </SocialButton>
            ) : (
              ''
            )}
            {socialMediaLinkInfo.twitter ? (
              <SocialButton
                variant="text"
                href={socialMediaLinkInfo.twitterUrl}
                spaceStackStart="m"
                target="_blank"
              >
                {TWITTER_ICON}
              </SocialButton>
            ) : (
              ''
            )}
            {socialMediaLinkInfo.linkedln ? (
              <SocialButton
                variant="text"
                href={socialMediaLinkInfo.linkedlnUrl}
                spaceStackStart="m"
                target="_blank"
              >
                {LINKENDIN_ICON}
              </SocialButton>
            ) : (
              ''
            )}
            {socialMediaLinkInfo.youtube ? (
              <SocialButton
                variant="text"
                href={socialMediaLinkInfo.youtubeUrl}
                spaceStackStart="m"
                target="_blank"
              >
                {YOUTUBE_ICON}
              </SocialButton>
            ) : (
              ''
            )}
            {socialMediaLinkInfo.vimeo ? (
              <SocialButton
                variant="text"
                href={socialMediaLinkInfo.vimeoUrl}
                spaceStackStart="m"
                target="_blank"
              >
                {VIMEO_ICON}
              </SocialButton>
            ) : (
              ''
            )}
          </React.Fragment>
        );
      })}
    </StyleSocialLinkLayout>
  );
};
