/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import { Layout, LayoutItem, useStyledTheme, Text, Button } from '@audi/audi-ui-react';
import { useI18n } from '@oneaudi/i18n-context';
import styled from 'styled-components';
import { transformServices } from '../../lib/transformServices';
import { messages } from '../../i18n/messages';
import { useTrackingManager } from '../useTrackingManager';
import { useMedia } from '../../lib/hooks';
import { Image } from './Image';
import { BOOKMARK_ICON } from './Icons';

const StyledImage = styled.figure`
  position: relative;
  margin: 0;
  /* create aspect ratio box with an aspect ratio of 16:9 */
  padding: 0 0 56.25% 0;
  overflow: hidden;

  > img {
    display: block;
    position: absolute;
    width: 100%;
    height: auto;
  }
`;

const StyledHeadlineAsLink = styled.div`
  > a {
    border-bottom: 0;
    font-weight: normal;
  }
`;

interface PartnerBusinessCardHeaderProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  partner: any;
  // eslint-disable-next-line react/require-default-props
  isNarrow?: boolean;
  displayOfficialName?: boolean;
  // eslint-disable-next-line react/require-default-props
  showImage?: boolean;
  // eslint-disable-next-line react/require-default-props
  showServiceList?: boolean;
  // eslint-disable-next-line react/require-default-props
  isLinkedToPWS?: boolean;
}

export const PartnerBusinessCardHeader = (
  props: PartnerBusinessCardHeaderProps,
): React.ReactElement => {
  const {
    partner,
    isNarrow,
    displayOfficialName = true,
    showImage = true,
    showServiceList = true,
    isLinkedToPWS = true,
  } = props;
  const trackingManager = useTrackingManager();

  const myAudiLinkLabel = useI18n(messages.myAudiLinkLabel);

  const partnerServiceLabels = new Map([
    ['sales', useI18n(messages.partnerServiceSales)],
    ['sales-r8', useI18n(messages.partnerServiceSalesR8)],
    ['service', useI18n(messages.partnerServiceService)],
    ['etron', useI18n(messages.partnerServiceEtron)],
    ['usedcars', useI18n(messages.partnerServiceUsedcars)],
  ]);
  const services: any = transformServices(
    partner.services,
    partner.additionalServices,
    partnerServiceLabels,
  );
  // // TODO Check imgWidth value
  const imgWidth = 328;

  const viewportTheme = useStyledTheme();

  const isViewportXS = useMedia(`only screen and (max-width: ${viewportTheme.breakpoints.s}px)`);
  const isViewportM = useMedia(`only screen and (min-width: ${viewportTheme.breakpoints.m}px) `);
  const isViewportL = useMedia(`only screen and (min-width: ${viewportTheme.breakpoints.l}px) `);

  const showColumnItemPicture = (showImage && isNarrow) || (showImage && !isViewportM && !isNarrow);
  const partnerName = displayOfficialName ? partner.officialName : partner.nonOfficialName;

  const partnerImage = (
    <StyledImage>
      <Image
        loadingAnimation="fade"
        src={`${partner.locationImageUrl}?width=${imgWidth}`}
        data-testid="image"
        alt={partner.officialName}
        sizes={[
          {
            condition: `(max-width: ${imgWidth}px)`,
            value: `${imgWidth}px`,
          },
          {
            condition: '(max-width: 639px)',
            value: '656px',
          },
          {
            condition: '(max-width: 1023px)',
            value: `${imgWidth}px`,
          },
          {
            value: '656px',
          },
        ]}
        srcSet={[
          {
            src: `${partner.locationImageUrl}?width=${imgWidth}`,
            desc: `${imgWidth}w`,
          },
          {
            src: `${partner.locationImageUrl}?width=656`,
            desc: '656w',
          },
        ]}
      />
    </StyledImage>
  );

  return (
    <Layout direction={{ xs: 'column', l: 'row' }} wrap="nowrap" align="stretch" justify="between">
      <LayoutItem align="stretch">
        <Text
          as={isViewportXS ? 'h3' : 'h2'}
          variant={isViewportXS ? 'order3' : 'order2'}
          weight="normal"
          data-testid="name"
        >
          {isLinkedToPWS ? (
            <StyledHeadlineAsLink>
              <a
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.url,
                    'headline',
                    partnerName,
                    partnerName,
                    '1',
                    'pws link',
                  )
                }
                href={partner.url}
                target="_blank"
                rel="noreferrer"
              >
                {partnerName}
              </a>
            </StyledHeadlineAsLink>
          ) : (
            partnerName
          )}
        </Text>
        {showServiceList ? (
          <Text
            weight="normal"
            data-testid="services"
            spaceStackStart="s"
            variant="order4"
            spaceStackEnd="xl"
          >
            {services}
          </Text>
        ) : null}
        {partner.locationImageUrl && showColumnItemPicture && (
          <LayoutItem>
            {isLinkedToPWS ? (
              <a
                onClick={() =>
                  trackingManager.click(
                    'exit_link',
                    partner.url,
                    'image',
                    '',
                    partnerName,
                    '1',
                    'pws link',
                  )
                }
                href={partner.url}
                target={isNarrow ? '_blank' : ''}
                rel="noreferrer"
              >
                {partnerImage}
              </a>
            ) : (
              partnerImage
            )}
          </LayoutItem>
        )}
      </LayoutItem>
      {false && (
        <LayoutItem
          spaceInlineStart={isViewportL ? 'xxl' : undefined}
          spaceStackStart={isViewportL ? 's' : 'xxl'}
          spaceStackEnd={!isViewportL ? 'm' : undefined}
        >
          <Button
            variant="text"
            href={partner.myAudiUrl}
            icon={BOOKMARK_ICON}
            onClick={() =>
              trackingManager.click(
                'internal_link',
                partner.myAudiUrl,
                'link',
                myAudiLinkLabel,
                partnerName,
                '',
                'myaudi link',
              )
            }
            data-testid="myaudi"
          >
            {useI18n(messages.myAudiLinkLabel)}
          </Button>
        </LayoutItem>
      )}
    </Layout>
  );
};
